import { browserTracingIntegration, replayIntegration, captureConsoleIntegration } from '@sentry/browser';
import { init } from '@jill64/sentry-sveltekit-cloudflare/client';

const onError = init('https://ad01837ac32807099ab0b9e2a47b96d6@o4506704480960512.ingest.sentry.io/4506704484171776', {
	sentryOptions: {
		integrations: [browserTracingIntegration(), replayIntegration(), captureConsoleIntegration({ levels: ['error'] })],
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0
	},
	enableInDevMode: false
});

export const handleError = onError((e, sentryEventId) => {
	console.log(e);
});
